import {initialise as initComponents} from './component'
import './component/Calendar'

initComponents()

const addHTMLClass = className=>document.documentElement.classList.add(className)

if (location.href.includes('http://localhost:8000')) {
  addHTMLClass('localhost')
}

Array.from(document.querySelectorAll('figure>iframe')).forEach(
  iframe=>iframe.parentNode.classList.add('video')
)

Array.from(document.querySelectorAll('a[href^=\'mailto:\']')).forEach(
  anchor=>anchor.innerHTML = anchor.textContent.replace(/(^.+@)(.*)(\..*$)/, '$1<span>$2</span>$3')
)

const merge = (acc, nr, i)=>acc+nr*(i===0?100:1)
const autumn = [23, 8].reduce(merge, 0)
const spring = [20, 4].reduce(merge, 0)
const today = new Date()
const todayMerged = [today.getMonth() + 1, today.getDate()].reduce(merge, 0)
const isAutumnWinter = todayMerged<spring || todayMerged>autumn
isAutumnWinter&&addHTMLClass('winter')
