import {create} from './index'
import {BaseComponent} from './BaseComponent'
import {expand} from '../utils/html'

/**
 * Now component
 */
create('[data-calendar]', class extends BaseComponent{

  constructor(...args){
    super(...args)
    const {_element} = this
    console.log('calendar',_element) // todo: remove log
    // _element.textContent = (new Date).getFullYear()
    // const options = this._parseOptions(_element.dataset.calendar)
    // const stringOptionsDate = { weekday: 'long', month: 'long', day: 'numeric' }
    // const stringOptionsTime = {hour: '2-digit', minute:'2-digit'}
    // const locale = 'nl-NL'
    // const space = ' '
    // const comma = ','
    // const dash = '-'
    // const nowDate = new Date()
    // const list = options.map(({start, end})=>{
    //     const startDate = new Date(start)
    //     const endDate = new Date(end)
    //     return {start, end, startDate, endDate}
    //   })
    //   .filter(({startDate})=>startDate>nowDate)
    //   .map(({start, startDate, endDate})=>{
    //     const startReadable = startDate.toLocaleString(locale, stringOptionsDate)
    //     const startTime = startDate.toLocaleTimeString(locale, stringOptionsTime)
    //     const endTime = endDate.toLocaleTimeString(locale, stringOptionsTime)
    //     const dateText = startReadable+comma
    //     const timeText = startTime+space+dash+space+endTime
    //     const href = this._mailto(startReadable)
    //     return `li>((time[datetime=${start}]>(span{${dateText}}+span{${timeText}}))+a[href="${href}"][target=_blank]{aanmelden})`
    //   })

    // _element.innerHTML = expand(`ul>(${list.join('+')})`)
  }

})
